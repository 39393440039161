.chat-input-root {
  align-items: center;
  background-color: #eeeeee;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
.chat-input-icons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  button {
    margin: 4px;
  }
}