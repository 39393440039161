.home-greeting-company-name {
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  line-height: 24px;
  font-size: 20px;
  text-transform: uppercase;
  color: #333333;
  margin: 0;
  span {
    color: #4ce1b6;
    font-weight: bold;
    font-size: 20px;
  }
}

.home-greeting-root {
  height: 96%;
  background-color: white;
  padding: 24px;
  display: flex;
  flex-direction: column;
}

.home-greeting-welcome {
  color: #333333;
  font-size: 20px;
}

.home-page-greeting-message {
  flex-grow: 1;
  display: flex;
  align-items: center;
  /* align-content: center; */
  justify-content: center;
}