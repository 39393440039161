.services-list{
  padding: 0px 30px;
  h5{
    font-weight: 500;
    line-height: 18px;
    font-size: 13px;
    text-transform: uppercase;
    color: #444444;
    padding: 35px 0px 10px 0px;
    margin: 0;
  }
   ul{
     padding: 0px;
     li{
       border-bottom: 1px solid #E0E0E0;
       padding: 14px 0px;
       &>div{
         padding: 0px;
       }
       span{
         font-weight: 500;
         line-height: 17px;
         font-size: 12px;
         color: #444444;
       }
     }
   }
}