.sidebar__block {
  border-bottom: 1px solid #eff1f5;
}

.sidebar__link {
  color: #555555;
}

.sidebar__link-title {
  font-size: 14px;
  line-height: 16px;
}
.app-drawer-list-item svg {
  color: #9e9e9e;
  height: 16px;
  margin-right: 8px;
}
.app-drawer-list-item {
  align-items: flex-end;
  display: flex;
  justify-content: flex-start;
  //margin-left: 16px;
  color: #555555;
  padding: 11px 20px;
  text-decoration: none;
  position: relative;
  &:before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 2px;
    background: #4ce1b6;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
}

.app-drawer-list-item p {
  margin: 0;
}
.app-drawer-list-item-li {
  :hover {
    background-color: #fafbfe;
  }
}

.app-drawer-list {
  padding: 240px 0;
}

.app-drawer-list-wrapper {
  padding: 20px 0;
}