@import "../../assets/scss/mixins";
@import "../../assets/scss/color-pallete";

.blue-btn{
  flex: 1;
  @include roundedBtn($blueColor, $whiteColor);
  &:not(:first-child){
    margin-left: 10px;
  }
  &.active{
    background: $blueColor;
    color: $whiteColor;
  }

}