.CircularProgressbar{
  width: 85px;
  height: 85px;
}

.percentage-block{
  background: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 30px;
  margin-bottom: 16px;
  p{
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    text-transform: uppercase;
    color: #999999;
    height: 100%;
    margin-top: 50px;
  }
}

