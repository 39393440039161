.chat-root {
  background-color: white;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}
.chat-file-error {
  background-color: #eeeeee;
  display: flex;
  color: red;
  font-weight: bold;
  justify-content: flex-end;
  padding-bottom: 1rem;
  padding-right: 1rem;
}