/* CIRCULAR PROGRESS BAR STYLES */

#app {
  margin-top: 40px;
}

#progressInput {
  margin: 20px auto;
  width: 30%;
}

.circle-background,
.circle-progress {
  fill: none;
  transition: .4s linear;
}

.circle-background {
  transition: .4s linear;
}

.circle-progress {
  transition: .4s linear;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.circle-text {
  line-height: 50px;
  font-size: 36px;
  text-align: center;
  text-transform: uppercase;
}