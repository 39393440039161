@import "./color-pallete";


/* MIXINS */

@mixin allCentered(){
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin roundedBtn($borderColor, $bgColor){
  @include allCentered;
  height: 38px;
  border-radius: 5px;
  border: 1px solid $borderColor;
  color: $borderColor;
  background: $bgColor;
  //margin-top: 10px;
  font-weight: 600;
  &:hover{
    cursor: pointer;
  }
}