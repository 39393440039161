.services-section{
  width: 100%;
}

.services-page-items-wrapper{
  display: grid;
  grid-template-rows: repeat(2, 290px);
  grid-template-columns: repeat(3, auto);
  grid-row-gap: 30px;
  grid-column-gap: 30px;
  width: 90%;
}

.services-page-item{
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  img{
    margin-bottom: 15px;
    margin-top: 35px;
  }
  p, .service-desc span{
    max-width: 300px;
    line-height: 18px;
    font-size: 13px;
    text-align: center;
    color: #999999;
    font-family: Roboto;
    margin-bottom: 35px;
    height: 55px;
    max-height: 55px;
    overflow: hidden;
    margin-top: 0px;
  }
  span{
    font-family: Roboto;
    line-height: 25px;
    font-size: 18px;
    text-align: center;
    color: #555555;
    margin-bottom: 15px;
  }
}

