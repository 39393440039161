.blue-small-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 38px;
  color: $color-white;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: $light;
  cursor: pointer;
  border-radius: 5px;
  background-color: $color-blue;
  outline: none;
  border: none;
}

.blue-small-btn-outlined {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 158px;
  height: 38px;
  color: $color-blue;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: $light;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid $color-blue;
  background-color: $color-white;
  outline: none;
}

.blue-big-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  color: $color-white;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: $light;
  cursor: pointer;
  border-radius: 5px;
  background-color: $color-blue;
  outline: none;
  border: none;
}

.blue-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $color-white;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: $light;
  cursor: pointer;
  border-radius: 5px;
  background-color: $color-blue;
  outline: none;
  border: none;
}

.cancel-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: $color-white;
  font-family: 'Roboto Medium';
  font-size: 14px;
  font-weight: $light;
  cursor: pointer;
  border-radius: 5px;
  background-color: $color-cancel;
  outline: none;
  border: none;
}