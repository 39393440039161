.projects{
    .react-grid-Cell:nth-child(10){
      .react-grid-Cell__value div span div{
        width: 100px;
        height: 22px;
        background: #4CE1B6;
        border-radius: 30px;
        color: #fff;
        font-size: 12px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
}