.profile-counter-root {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.profile-counter-num {
  color: #42a5f5;
  font-weight: bold;
}
.profile-counter-text {
  color: #bdbdbd;
  font-size: .8rem;
  text-align: center;
}