.page-title{
  color: #555555;
  font-size: 24px;
  font-weight: bold;
}

.page-desc{
  font-size: 16px;
  color: #999999;
}

.section-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
  margin-top: 10px;
  h5, p{
    margin: 0px;
  }
  h5{
    margin-bottom: 15px;
  }
  .left-side{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .right-side{
    height: 100%;
    display: flex;
    align-items: center;
  }
}
