.chat-message-inner-root {
  align-items: center;
  display: flex;
  margin: 24px 0;
}
.chat-message-inner-text {
  color: #616161;
  flex-grow: 1;
  word-break: break-all;
}
.chat-message-inner-user {
  border-radius: 50%;
  font-weight: bold;
  display: flex;
  font-size: .8rem;
  align-items: center;
  justify-content: center;
  margin: 12px;
  height: 48px;
  padding: 32px;
  width: 48px;
}
.chat-message-inner-client {
  background-color: #eeeeee;
}
.chat-message-inner-admin {
  color: white;
  background-color: #212121;
}
.chat-message-inner-created {
  display: flex;
  color: #bdbdbd;
  flex-direction: column;
}