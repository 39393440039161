.green-btn{
  width: 270px;
  height: 55px;
  background: #28CDBF;
  color: #fff;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  margin-right: 4px;
  border: none;
  &:hover{
    cursor: pointer;
  }
}
.green-empty-btn{
  border: 2px solid #DAD9E2;
  width: 170px;
  height: 55px;
  background: #fff;
  color: #28CDBF;
  font-size: 16px;
  font-weight: bold;
  /*text-transform: uppercase;*/
  margin-right: 4px;
  margin-bottom: 4px;
  //border: none;
  &:hover{
    cursor: pointer;
  }
}

.gray-btn{
  background: #DAD9E2;
  color: #fff;
  width: 136px;
  height: 38px;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  &:hover{
    cursor: pointer;
  }
}

.blue-save-btn{
    background: #70bbfd;
    color: #fff;
    width: 136px;
    height: 38px;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    border: none;
    &:hover{
        cursor: pointer;
    }
}

.plus-btn{
  &:hover{
    cursor: pointer;
  }
  border: none;
  background: transparent;
}

.new-petition-options{
  @media (orientation: landscape) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 0px;
  }
  @media (orientation: portrait) {
    display: flex;
    flex-direction: column;
  }
  button {
    margin: 12px 0;
  }
}

.petition-info{
  display: flex;
  align-items: center;
}

.petition-info button {
  margin: 4px 0;
}

.petition-number{
  background: #70BBFD;
  height: 125px;
  color: #fff;
  font-size: 26px;
  line-height: 36px;
  padding: 0px 30px;
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.line{
  margin: 25px 0px;
  display: inline-block;
  height: 2px;
  background: #DDDDDD;
  width: 100%;
}


.petitions-area{
  height: auto;
  min-height: 270px;
  background: #fff;
  margin-bottom: 45px;
}


.empty-table{
  display: flex;
  justify-content: center;
  margin-top: 70px;
  line-height: 25px;
  svg{
    margin-right: 10px;
  }
  &:hover{
    cursor: pointer;
  }
}

.btn-right{ 
    text-align: right; 
}