$color-accent: #4ce1b6;
$color-accent-hover: darken($color-accent, 10%);
$color-additional: #999999;
$color-additional-hover: darken($color-additional, 10%);

$color-yellow: #f6da6e;
$color-yellow-hover: darken($color-yellow, 10%);

$color-violet: #c88ffa;

$color-red: #ff4861;
$color-red-hover: darken($color-red, 10%);

$color-blue: #70bbfd;
$color-blueD: #546497;
$color-blue-hover: darken($color-blue, 10%);

$color-white: #ffffff;
$color-whiteD: #f2f2f2;
$color-white-blue: #f8f9fb;
$color-sky: #38a0fa;
$color-sk: #5e6a6e;
$color-purple: #161360;


$color-dark: #555555;
$color-grey: #555555;
$color-greyL: #444444;
$color-greyB: #999999;
$color-greyU: #dad9e2;
$color-some: #eaeaea;
$color-cancel: #e7e2e2;
$color-hz: #e3eded;
$color-greenL: #00e49a;

/// fontsw

$veryBold: 900;
$bold: 700;
$almostBold: 600;
$light: 500;
$veryLight: 400;