@import '../../assets/scss/color-pallete';
@import '../../assets/scss/mixins';
$blue: #3174ad;
$pink: #ad3173;

/* DASHBOARD STYLES */


.dashboard-section-wrapper-table{
  display: grid;
  grid-template-areas: "percInfo notSlider servList"
                       "projectsTable projectsTable projectsTable";
  grid-template-columns: 1fr 2fr 1fr;
  grid-template-rows: 405px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
}

.percentage-info{
  grid-area: percInfo;
  display: grid;
  grid-row-gap: 12px;

}

.notifications-slider{
  grid-area: notSlider;
  background: $whiteColor;
  height: 96%;
}

.services-list{
  grid-area: servList;
  background: $whiteColor;
}

.projects-table{
  grid-area: projectsTable;
  .react-grid-Container{
    width: auto !important;
  }
}

.dashboard-section-wrapper-calendar{
  display: grid;
  grid-template-areas: "calendar addition1 addition2";
  grid-template-columns: 2fr 1fr 1fr;
  grid-template-rows: 390px;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  margin: 20px 0px;
}

.calendar-block{
  grid-area: calendar;
}

.addition-block:first-child{
  grid-area: addition1;
}

.addition-block:last-child{
  grid-area: addition2;
}


/* ADDITIONS BLOCK STYLES */

.addition-block{
  @include allCentered;
  flex-direction: column;
  span{
    font-size: 18px;
    line-height: 21px;
    font-weight: bold;
    margin-top: 35px;
  }
  p{
    font-size: 13px;
    line-height: 18px;
    width: 300px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 55px;
  }
}

.addition-block-btns{
  display: flex;
    button{
      width: 105px;
      height: 30px;
      border-radius: 5px;
      @include allCentered;
      font-size: 13px;
      line-height: 18px;
      border: 1px solid;
      margin: 0px 5px;
      &:hover{
        cursor: pointer;
      }
    }
}


/* CALENDAR STYLES */

.rbc-row{
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0px, 1fr));
  height: 100%;
}


.rbc-month-row{
  border-bottom: 1px solid #dddddd;
  &:last-child{
    border: none;
  }
}

.rbc-row-content{
  height: 100%;
}

.rbc-month-header{
  height: 24px;
  background: #F5F5F5;
  border-bottom: 1px solid #dddddd;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 17px;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #444444;
}

.rbc-header, .rbc-date-cell{
  border-right: 1px solid #dddddd;
  display: flex;
  &:last-child{
    border: none;
  }
  &.rbc-off-range{
    a{
      color: #999999;
    }
  }
}

.rbc-month-view{
  //grid-template-rows: repeat(auto-fit, minmax(0px, 1fr));
  //grid-auto-rows: 1fr;
  display: grid;
  flex: 1;
  border: 1px solid #dddddd;
  grid-template-rows: 24px;
  //grid-auto-rows: minmax(10px, auto);
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  font-size: 13px;
  text-transform: uppercase;
}

.rbc-month-view a{
  padding: 5px;
  display: flex;
  flex: 1 1;
  text-decoration: none;
  color: #444444;
  transition: .2s linear;
  &:hover{
    background: #F5F5F5;
    transition: .2s linear;
  }
}


.rbc-calendar{
  display: flex;
  flex-direction: column;
  flex: 1;
}

.calendar-block{
  display: flex;
  padding: 10px 50px 15px;
  background: #fff;
}

.rbc-toolbar{
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  &-label{
    font-style: normal;
    font-weight: bold;
    line-height: 16px;
    font-size: 14px;
    text-transform: uppercase;
    color: #444444;
  }
}

.rbc-header{
  display: flex;
  align-items: flex-end;
  justify-content: center;
}


.rbc-btn-group{
  display: none;
}