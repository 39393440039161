.auth {
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 425px;

  .auth-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 900px;
    background: $color-white;

    @media only screen and (max-width: 450px) {
      height: 600px;
      margin-bottom: 25px;
    }

    &__title-wrapper {
      display: flex;
      margin-bottom: 31px;
      width: 325px;
    }

    &__decor {
      display: flex;
      margin-right: 8px;
      width: 3px;
      height: inherit;
      background: $color-blue;
    }

    &__parts {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &__litle-parts {
      display: flex;
      flex-direction: row;
    }

    &__first-part {
      display: flex;
      margin-right: 6px;
      color: $color-dark;
      font-family: Roboto;
      font-size: 24px;
      font-weight: $veryLight;
    }

    &__second-part {
      display: flex;
      color: $color-dark;
      font-family: Roboto;
      font-size: 24px;
      font-weight: $veryBold;
    }

    &__sub-part {
      display: flex;
      color: color-greyB;
      font-family: Roboto;
      font-size: 12px;
      font-weight: $veryLight;
    }

    &__wrapper {
      width: 325px;
    }

    &__user {
      display: flex;
      align-self: flex-start;
      margin-bottom: 10px;
      color: $color-greyL;
      font-family: Roboto;
      font-size: 13px;
      font-weight: $veryLight;
    }

    &__user-placeholder,
    &__pass-placeholder,
    &__remember-pass {
      display: flex;
      align-items: center;
      margin-bottom: 23px;
      cursor: pointer;
    }

    &__remember-pass {
      margin-bottom: 20px;
      color: $color-greyL;
      font-family: Roboto;
      font-size: 13px;
      font-weight: $veryLight;
    }

    &__remember-checkbox {
      margin-right: 8px;
      padding: 0;
    }

    &__forget-pass {
      display: flex;
      justify-content: flex-end;
      color: $color-blue;
      font-family: Roboto;
      font-size: 11px;
      font-weight: $veryLight;
    }

    &__btn-wrapper {
      display: flex;
    }

    &__btn-inner-wrapper {
      margin-right: 10px;
    }

    &__register-btn-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    &__register-signup-btn {
      margin-bottom: 20px;
      width: 326px;
      color: $color-white;
      font-family: Roboto;
      font-size: 14px;
      font-weight: $light;
    }

    &__register-inner-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    &__have-account-question,
    &__have-account-sub {
      display: flex;
      margin-bottom: 10px;
      color: $color-greyL;
      font-family: Roboto;
      font-size: 13px;
      font-weight: $veryLight;
    }

    &__have-account-sub {
      color: $color-blue;
    }

    &__blue-big-btn {
      height: 38px;
      width: 326px;
      color: $color-white;
      font-family: 'Roboto Medium';
      font-size: 14px;
      font-weight: $light;
      background: $color-blue;
      border: none;
      outline: none;
    }
    &__message-wrapper {
      display: flex;
      justify-content: center;
      align-self: center;
      align-items: center;
      text-align: center;
      color: #aab2b7;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;

      span {
        width: 279px;
      }
    }
  }
}

.reg {
  display: flex;
  flex-direction: column;
  padding-top: 65px;
  padding-left: 50px;
  padding-right: 50px;
  background-color: $color-white;

  .reg-form {
    &__greeting {
      display: flex;
      margin-bottom: 17px;
      color: $color-grey;
      font-family: Roboto;
      font-size: 24px;
      font-weight: $bold;
    }

    &__greeting-inner {
      margin-right: 7px;
    }

    &__message {
      margin-bottom: 67px;
      color: $color-grey;
      font-family: Roboto;
      font-size: 18px;
      font-weight: $veryLight;
    }

    &__company-block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      max-width: 600px;

      &:last-child {
        margin-bottom: 85px;
      }

      @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    &__reg-form__phone {
      margin-bottom: 85px;
    }

    &__company {
      color: $color-greyL;
      font-family: Roboto;
      font-size: 13px;
      font-weight: $veryLight;
    }

    &__contact-person {
      color: $color-grey;
      font-family: Roboto;
      font-size: 14px;
      font-weight: $bold;
      text-transform: uppercase;
    }

    &__company-services {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 600px;
    }

    &_services {
      display: flex;
      flex-direction: column;
    }

    &__services-block {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      padding-left: 45px;
    }

    &__rervice-question {
      align-self: flex-start;
      padding-top: 12px;
    }

    &__reg-btn-wrapper {
      width: 103px;
      height: 38px;
      margin-right: 20px;
    }
  }
}

.welcome-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
  background: $color-whiteD;

  &__bg-img {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 50px;
    user-select: none;

    span {
      display: flex;
      align-self: center;
      max-width: 403px;
      font-family: Poppins;
      font-style: normal;
      font-weight: bold;
      line-height: 51px;
      font-size: 36px;
      text-align: center;
      letter-spacing: 2px;
      color: $color-blueD;

      @media only screen and (max-width: 500px) {
        padding-left: 20px;
        padding-right: 20px;
        font-size: 24px;
        line-height: 41px;
      }
    }
  }

  &__admin-logo {
    display: flex;
    align-self: flex-start;
    padding-left: 70px;

    @media only screen and (max-width: 500px) {
      padding-left: 25px;
    }
  }

  &__img {
    width: 100%;
    height: auto;
  }
}

.auth-support {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 75px;
  width: 370px;

  @media screen and(max-width: 955px) {
    padding-top: 0px;
  }

  &__supporter {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 25px;
    padding-top: 28px;
    padding-left: 32px;
    padding-right: 32px;
    background: $color-white;
  }

  &__title {
    margin-bottom: 5px;
    color: $color-sky;
    font-family: 'Poppins Semi Bold';
    font-size: 12px;
    font-weight: $almostBold;
    letter-spacing: 0.5px;
  }

  &__name {
    color: $color-sk;
    font-family: Poppins;
    font-size: 12px;
    font-weight: $veryLight;
    letter-spacing: 0.5px;
  }

  &__message {
    margin-bottom: 27px;
    padding-left: 32px;
    padding-right: 32px;
    color: $color-purple;
    box-shadow: 0 5px 20px #f6f9fe;
    border-radius: 6px;
    border: 1px solid $color-hz;
    background-color: $color-white;
  }

  &__faq-btn,
  &__contact-btn {
    width: 133px;
    height: 37px;
    color: $color-white;
    border-radius: 6px;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: $color-greenL;
  }

  &__contact-btn {
    color: $color-greenL;
    border: 1px solid $color-greenL;
    background: $color-white;
  }

  &__buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 31px;
    padding-left: 32px;
    padding-right: 32px;
  }
}

.final-reg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 62px;
  padding-left: 60px;
  padding-right: 60px;
  max-width: 860px;

  &__bg-block {
    display: flex;
    flex-direction: row;
    margin-bottom: 65px;
  }

  &__final-img {
    width: 90%;
    height: auto;
  }

  &__greeting {
    color: $color-grey;
    font-family: Roboto;
    font-size: 24px;
    font-weight: $bold;
  }

  &__final-text {
    margin-bottom: 15px;
    color: $color-grey;
    font-family: Roboto;
    font-size: 18px;
    font-weight: 500;
  }

  &__final-text-sub {
    margin-bottom: 15px;
    color: $color-grey;
    font-size: 18px;
    font-family: Roboto;
    font-weight: 400;
  }

  &__final-block {
  }
}

.auth-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;

  &__children {
    align-items: center;
    align-content: center;
    background: $color-whiteD;
  }
}

.reg-wrapper {
  display: flex;
  flex-direction: row;
  background-color: $color-white-blue;

  .reg-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    padding-left: 54px;
    padding-top: 25px;
    width: 100%;
    height: 60px;
    background-color: $color-white;

    &__call {
      margin-right: 180px;
      padding-bottom: 8px;
    }
  }
}
