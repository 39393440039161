.chat-header-root {
  align-items: flex-start;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 20px;
}

.chat-header-not-selected {
  background-color: white;
  border: 1px solid #e0e0e0;
}

.chat-header-selected {
  background-color: #eeeeee;
  border: 1px solid #e0e0e0;
}

.chat-header-message {
  color: #bdbdbd;
  //padding: 0 20px;
  word-break: break-all;
}

.chat-header-avatar {
  margin-right: 20px;
}

.chat-header-name {
  font-weight: bold;
  margin-bottom: 4px;
}