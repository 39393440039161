.profile-root {
  padding: 20px;
}
.profile-info-block {
  display: flex;
  justify-content: flex-start;
}
.profile-avatar {
  margin: 0 20px 20px 0;
}
.profile-counters {
  display: flex;
  justify-content: space-around;
  padding-top: 20px;
}

.profile-root b {
  word-break: break-all;
}