.chat-message-root {
  align-items: center;
  display: flex;
  margin: 4px 0;
}

.chat-message-text {
  padding: 12px;
  margin: 12px;
  //min-width: 30%;
  max-width: 60%;
  word-break: break-all;
}

.chat-message-flex-start {
  justify-content: flex-start;
}

.chat-message-flex-end {
  justify-content: flex-end;
}

.chat-message-client-text {
  border-bottom-right-radius: 12px;
  //border-top-right-radius: 12px;
  background-color: #eeeeee;
}

.chat-message-guest-text {
  //background-color: #e3f2fd;
  background-color: #eeeeee;
  border-bottom-left-radius: 12px;
  //color: white;
  text-align: right;
  //border-top-left-radius: 12px;
}

.chat-message-created {
  color: #bdbdbd;
  font-style: italic;
  margin-bottom: 4px;
}

.chat-message-client {
  text-align: right;
}

.chat-message-guest {
  text-align: left;
}