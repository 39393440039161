.language-select {
  position: absolute;
  top: 6px;
  right: 46px;

  @media only screen and (max-width: 500px) {
    right: 20px;
  }

  &__select {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /*&__select-item {
  }*/

  &__select-flag {
    margin-right: 8px;
  }

  &__select-lang {
    color: $color-greyB;
    font-family: Roboto;
    font-size: 14px;
    font-weight: $veryLight;
    line-height: 9.3px;
    text-transform: uppercase;
  }
}
