.card-header{
  border-left: 5px solid #50E3C2;
  padding: 0px 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 75px;
  .card-title{
    color: #555555;
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    margin-top: 0px;
    text-transform: uppercase;
  }

  .card-desc{
    font-size: 12px;
    color: #999999;
    margin: 0px;
  }
}