.projects-table.products{
  .items-per-page{
    display: flex;
    align-items: center;
  }
  button{
    padding: 0px 15px;
  }
  .table-search{
    button{
      margin-left: 20px;
    }
  }
}