.project-info-block-title {
  background-color: #eeeeee;
  padding: 8px;
  margin-right: 16px;
  @media (orientation: landscape) {
    width: 40%
  }
  @media (orientation: portrait) {
    margin-bottom: 4px;
    width: 100%;
  }
}
.project-info-block-value {
  font-weight: bold;
  @media (orientation: landscape) {
    width: 60%
  }
  @media (orientation: portrait) {
    width: 100%;
  }
}
.project-info-block-row {
  margin: 2rem 0;
  display: flex;
  justify-content: flex-start;
  @media (orientation: landscape) {
    align-items: center;
  }
  @media (orientation: portrait) {
    flex-direction: column;
  }
}
