.planning-date-block {
  margin-bottom: 24px;
}
.planning-date-text {
  color: #5e6a6e;
  margin-bottom: 2px;
}
.planning-date-icon {
  margin-right: 8px;
}
.planning-date-date {
  font-weight: bold;
}
