*{
    outline: none !important;
}
.projects-table{
    padding: 35px 30px;
    background: #fff;

    h5{
       margin: 5px 0px 55px 5px;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
        color: #555555;
    }
    .rdg-selected{
        border: none;
    }
    .react-grid-HeaderRow, .react-grid-Canvas{
        //overflow: auto;
    }
    .react-grid-Canvas{
        overflow: auto scroll;
    }
    .react-grid-Grid{
        border: none;
    }
    .react-grid-Header, .react-grid-HeaderCell{
        background: #fff;
        font-size: 12px;
        font-weight: bold;
        font-family: Roboto;
        color: #555555;
        padding: 0px;
    }
    .react-grid-Cell{
        padding: 0px;
        height: 42px !important;
        border: none;
        background: transparent;
        &:first-child{
            padding-left: 20px;
        }
    }
    .react-grid-Row{
        height: 42px !important;
        border-bottom: 1px solid #D8D8D8;

        &:nth-child(2n){
            background: #F8F8F8;
        }
        &:first-child{
            border-top: 1px solid #D8D8D8;
        }

    }
    .react-grid-Cell__value{
        font-size: 13px;
        color: #555555;
    }

    .react-grid-HeaderCell{
        display: flex !important;
        align-items: center;
        &:first-child{
            padding-left: 20px;
        }
    }

    .table-search{
        display: flex;
        align-items: center;
        position: relative;
        input{
            width: 95px;
            height: 24px;
            border: 1px solid #f0f0f0;
            border-radius: 3px;
            padding: 0px 25px 0px 10px;
            color: #444444;
            font-size: 10px;
            &::placeholder{
                color: #999999;
                font-size: 10px;
            }
        }
    }

    .projects-table-options{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;
    }
    .items-per-page{
        font-size: 12px;
        color: #555555;
        input{
            height: 24px;
            width: 32px;
            padding-left: 8px;
            border-radius: 3px;
            border: 1px solid #f0f0f0;
            margin: 0px 7px;
            &::placeholder{
                color: #999999;
                font-size: 10px;
            }
        }
    }
}

