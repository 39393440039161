@import './fonts.scss';

/* COMMON STYLES */

body{
  height: 100vh;
  margin: 0px;
  font-family: 'Roboto', sans-serif;
  background: #F2F2F2;
}

#root, .App{
  width: 100%;
  height: 100%;
}

.App{
  display: flex;
}

*{
  outline: none;
}

main{
  display: flex;
  flex: 1;
  //padding-top: 60px;
  section{
    //padding: 30px;
    flex: 1;
    //padding-top: 60px;
  }
}

.page-content{
  flex: 1;
  display: flex;
}

.red-mark{
  color: #AD4444;
}
