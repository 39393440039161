/* NOTIFICATIONS SLIDER STYLES */
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

/* FONTS */

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,300i,400,400i,500,500i,600,600i,700&subset=cyrillic,cyrillic-ext,latin-ext');

@import '../../assets/scss/mixins';


.test{
  width: 300px;
}

.slick-slider{
  max-width: 400px;
}

.notifications-slider{

  position: relative;
  overflow: hidden;
  padding: 35px 0px 30px 40px;
  .header-block{
    margin-left: 30px;
    //margin-bottom: 60px;
  }
  span{
    font-weight: 500;
    line-height: 18px;
    font-size: 18px;
    color: #444444;
  }
  h5{
    font-family: Montserrat;
    font-style: normal;
    font-weight: bold;
    line-height: 24px;
    font-size: 20px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 12px;
    margin-top: 0px;
    span{
      color: #4CE1B6;
      font-weight: bold;
      font-size: 20px;
    }
  }
  &:before{
    box-shadow: inset -9px 0px 40px 30px #fff;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: "";
    z-index: 10;
  }
}

.carousel-area{
  display: grid;
  grid-template-columns: repeat(3, 385px);
  grid-column-gap: 20px;
  width: 740px;
  overflow: scroll;
  position: absolute;
  scroll-behavior: smooth;
  padding: 10px;
  &::-webkit-scrollbar{
    display: none;
  }

}


.slide-item{
  width: 385px;
  height: 165px;
  background: #fff;
  box-shadow:  0px 5px 10px rgba(0, 0, 0, 0.0970335);
  .slide-item-wrapper{
    padding: 30px 25px 35px 60px;
    h5{
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      line-height: 23px;
      font-size: 16px;
      color: #555555;
      margin-bottom: 0px;
      text-transform: capitalize;
      height: 22px;
      overflow: hidden;
    }
    span{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      line-height: 16px;
      font-size: 11px;
      color: #555555;
      opacity: 0.35;
    }
    p{
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      line-height: 18px;
      font-size: 13px;
      color: #999999;
      margin-top: 8px;
    }
  }
}

.scroll-dots{
  position: absolute;
  bottom: 32px;
  display: flex;
  width: 50px;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;
  align-self: center;
  left: calc(50% - 25px);
  z-index: 20;
  button{
    width: 10px;
    height: 10px;
    background: #D8D8D8;
    border-radius: 10px;
    border: none;
    padding: 0px;
    &:hover{
      cursor: pointer;
    }
  }
}

.scroll-btns{
  margin: 10px 70px 25px 0px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 20;
  button{
    @include allCentered;
    width: 24px;
    height: 24px;
    background: transparent;
    -webkit-border-radius: 2px;
    -moz-border-radius: 2px;
    border-radius: 2px;
    border: 1px solid #E0E0E0;
    margin: 0px 3px;
    font-family: Roboto;
    font-size: 17px;
    color: #D8D8D8;
    &:hover{
      cursor: pointer;
    }
  }
}